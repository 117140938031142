import React from "react";
import { Switch, Route } from "react-router-dom";
import { Pages } from "./pages";
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import OrdinaryRoute from './Utils/OrdinaryRoute';


export type ConfirmRegistrationUrlParams = {
    idMembers: string,
    secureCode: string
}

export type UpgradeOfferDetailUrlParams = {
    id: string
}

export type CompetitionUrlParams = {
    id: string
}

export type ApplicationUrlParams = {
    id: string,
    idCompetitions: string,
    secureCode: string
}

export type ApplicationListByCompetitionsUrlParams = {
    idCompetitions: string
    
}

export type ApplicationListByCompetitionsMasterUrlParams = {
    idCompetitions: string
    password: string
}

export type ApplicationListByPeopleUrlParams = {
    idCompetitions: string
    password: string
}

export const ROUTES = {
    /* ACCOUNTS */
    login: {
        route: "/login",
        getLink: () => {
            return ROUTES.login.route
        } 
    },
    logout: {
        route: "/logout",
        getLink: () => {
            return ROUTES.logout.route
        } 
    },  
    myAccount: {
        route: "/my-account",
        getLink: () => {
            return ROUTES.myAccount.route
        } 
    },     

    registration: {
        route: "/registration",
        getLink: () => {
            return ROUTES.registration.route
        } 
    },  

    confirmRegistration: {
        route: "/confirm-registration/:idMembers/:secureCode",
        getLink: (idMembers: string, secureCode: string) => {
            return ROUTES.application.route.replace(":idMembers", idMembers.toString()).replace(":secureCode", secureCode.toString())
        } 
    },


    dashboard: {
        route: "/dashboard",
        getLink: () => {
            return ROUTES.dashboard.route
        } 
    },

    members: {
        route: "/members",
        getLink: () => {
            return ROUTES.members.route
        } 
    },

    dialsClasses: {
        route: "/dials-classes",
        getLink: () => {
            return ROUTES.dialsClasses.route
        } 
    },

    dialsStyles: {
        route: "/dials-styles",
        getLink: () => {
            return ROUTES.dialsStyles.route
        } 
    },
    
    dialsLevels: {
        route: "/dials-levels",
        getLink: () => {
            return ROUTES.dialsLevels.route
        } 
    },

    dialsCompetitionTypes: {
        route: "/dials-competition-types",
        getLink: () => {
            return ROUTES.dialsCompetitionTypes.route
        } 
    },

    dialsCompetitionVariants: {
        route: "/dials-competition-variants",
        getLink: () => {
            return ROUTES.dialsCompetitionVariants.route
        } 
    },    

    dialsAgeCategories: {
        route: "/dials-age-categories",
        getLink: () => {
            return ROUTES.dialsAgeCategories.route
        } 
    },

    dialsCompetitions: {
        route: "/dials-competitions",
        getLink: () => {
            return ROUTES.dialsCompetitions.route
        } 
    },    

    adjudicator: {
        route: "/adjudicator",
        getLink: () => {
            return ROUTES.adjudicator.route
        } 
    },

    master: {
        route: "/master",
        getLink: () => {
            return ROUTES.master.route
        } 
    },

    competition: {
        route: "/competition/:id",
        getLink: (id: string) => {
            return ROUTES.competition.route.replace(":id", id.toString())
        } 
    },

    competitionSchedule: {
        route: "/competitions-list",
        getLink: () => {
            return ROUTES.competitionSchedule.route
        } 
    },

    application: {
        route: "/application/:id/:idCompetitions/:secureCode",
        getLink: (id: string, idCompetitions: string, secureCode: string) => {
            return ROUTES.application.route.replace(":id", id.toString()).replace(":idCompetitions", idCompetitions.toString()).replace(":secureCode", secureCode.toString())
        } 
    },

    applicationMaster: {
        route: "/application963852741/:id/:idCompetitions/:secureCode",
        getLink: (id: string, idCompetitions: string, secureCode: string) => {
            return ROUTES.applicationMaster.route.replace(":id", id.toString()).replace(":idCompetitions", idCompetitions.toString()).replace(":secureCode", secureCode.toString())
        } 
    },

    applicationListByCompetitions: {
        route: "/application-list/:idCompetitions",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByCompetitions.route.replace(":idCompetitions", idCompetitions.toString())
        } 
    },

    applicationListByCompetitionsMaster: {
        route: "/application-list-master/:idCompetitions/:password",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByCompetitionsMaster.route.replace(":idCompetitions", idCompetitions.toString()).replace(":password", password.toString())
        } 
    },

    applicationListByPeople: {
        route: "/application-list-by-app/:idCompetitions/:password",
        getLink: (idCompetitions: number, password: string) => {
            return ROUTES.applicationListByPeople.route.replace(":idCompetitions", idCompetitions.toString()).replace(":password", password.toString())
        } 
    },

}

export const RouterSwitch = () => <Switch>
    <Route path="/" exact component={Pages.Home} />
    
    <OrdinaryRoute path={ROUTES.registration.route} component={Pages.AccountsRegistration} />
    <OrdinaryRoute path={ROUTES.confirmRegistration.route} component={Pages.AccountsConfirmRegistration} />

    <OrdinaryRoute path={ROUTES.competition.route} component={Pages.Competition} />
    <OrdinaryRoute path={ROUTES.competitionSchedule.route} component={Pages.CompetitionSchedule} />
    <OrdinaryRoute path={ROUTES.application.route} component={Pages.Application} />
    <OrdinaryRoute path={ROUTES.applicationMaster.route} component={Pages.ApplicationMaster} />
    <OrdinaryRoute path={ROUTES.applicationListByCompetitions.route} component={Pages.ApplicationListByCompetitions} />
    <OrdinaryRoute path={ROUTES.applicationListByCompetitionsMaster.route} component={Pages.ApplicationListByCompetitionsMaster} />
    <OrdinaryRoute path={ROUTES.applicationListByPeople.route} component={Pages.ApplicationListByPeople} />
    <PublicRoute path={ROUTES.login.route} component={Pages.AccountsLogin} />

    <PrivateRoute path={ROUTES.logout.route} component={Pages.AccountsLogout} />
    <PrivateRoute path={ROUTES.myAccount.route} component={Pages.AccountsMyAccount} />
    <PrivateRoute path={ROUTES.dashboard.route} component={Pages.Dashboard} />
    <PrivateRoute path={ROUTES.members.route} component={Pages.Members} />

    <PrivateRoute path={ROUTES.dialsClasses.route} component={Pages.DialsClasses} />
    <PrivateRoute path={ROUTES.dialsStyles.route} component={Pages.DialsStyles} />
    <PrivateRoute path={ROUTES.dialsLevels.route} component={Pages.DialsLevels} />
    <PrivateRoute path={ROUTES.dialsCompetitionTypes.route} component={Pages.DialsCompetitionTypes} />
    <PrivateRoute path={ROUTES.dialsCompetitionVariants.route} component={Pages.DialsCompetitionVariants} />
    <PrivateRoute path={ROUTES.dialsAgeCategories.route} component={Pages.DialsAgeCategories} />
    <PrivateRoute path={ROUTES.dialsCompetitions.route} component={Pages.DialsCompetitions} />
    
    <PrivateRoute path={ROUTES.adjudicator.route} component={Pages.Adjudicator} />
    <PrivateRoute path={ROUTES.master.route} component={Pages.Master} />
    

    
    
    <Route>
        <>404</>
    </Route>
</Switch>