import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios'

axios.get('appsettings.json').then(response => {
    sessionStorage.setItem('URL', response.data.apiendpoint)
    sessionStorage.setItem('isTestRelease', response.data.testrelease)
    ReactDOM.render(<App />, document.getElementById('root'));
  })


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
