import { getLoadableComponent } from "../sharedComponents/LoadableComponent/LoadableComponent"

export const Pages = {
  Home: getLoadableComponent(() => import('./home/Home.page')),
  
  Competition: getLoadableComponent(() => import('./competition/Competition.page')),
  CompetitionSchedule: getLoadableComponent(() => import('./competitionSchedule/CompetitionSchedule.page')),
  Application: getLoadableComponent(() => import('./application/Application.page')),
  ApplicationMaster: getLoadableComponent(() => import('./application/ApplicationMaster.page')),
  ApplicationListByCompetitions: getLoadableComponent(() => import('./applicationList/ApplicationListByCompetitions.page')),
  ApplicationListByCompetitionsMaster: getLoadableComponent(() => import('./applicationList/ApplicationListByCompetitionsMaster.page')),
  ApplicationListByPeople: getLoadableComponent(() => import('./applicationList/ApplicationListByPeople.page')),

  /* ACCOUNTS */
  AccountsLogin: getLoadableComponent(() => import('./accounts/login/Accounts.Login.page')),
  AccountsLogout: getLoadableComponent(() => import('./accounts/logout/Accounts.Logout.page')),
  AccountsMyAccount: getLoadableComponent(() => import('./accounts/myAccount/Accounts.MyAccount.page')),
  AccountsRegistration: getLoadableComponent(() => import('./accounts/registration/Accounts.Registration.page')),
  AccountsConfirmRegistration: getLoadableComponent(() => import('./accounts/confirmRegistration/Accounts.ConfirmRegistration.page')),

  Dashboard: getLoadableComponent(() => import('./dashboard/Dashboard.page')),
  Members: getLoadableComponent(() => import('./members/Members.page')),

  DialsClasses: getLoadableComponent(() => import('./dials/classes/Dials.Classes.page')),
  DialsStyles: getLoadableComponent(() => import('./dials/styles/Dials.Styles.page')),
  DialsLevels: getLoadableComponent(() => import('./dials/levels/Dials.Levels.page')),
  DialsCompetitionTypes: getLoadableComponent(() => import('./dials/competitionTypes/Dials.CompetitionTypes.page')),
  DialsCompetitionVariants: getLoadableComponent(() => import('./dials/competitionVariants/Dials.CompetitionVariants.page')),
  DialsAgeCategories: getLoadableComponent(() => import('./dials/ageCategories/Dials.AgeCategories.page')),
  DialsCompetitions: getLoadableComponent(() => import('./dials/competitions/Dials.Competitions.page')),



  Adjudicator: getLoadableComponent(() => import('./adjudicator/Adjudicator.page')),
  Master: getLoadableComponent(() => import('./master/Master.page')),

    
}